import React from "react";
import { Box } from "@mui/material";
import PrizesInfoContent from "../Assets/PrizesInfoContent.png";

function PrizeInfo() {
  return (
    <Box>
      <Box
        component="img"
        alt="Prize Info"
        src={PrizesInfoContent}
        sx={{ width: "100%" }}
      />
    </Box>
  );
}

export default PrizeInfo;
