import React from "react";
import CommonParagraph from "./CommonParagraph";

const sentence1 = `The National University of Singapore (NUS) is Singapore’s flagship university, which offers a global approach to education, research and entrepreneurship, with a focus on Asian perspectives and expertise. We have 16 colleges, faculties and schools across three campuses in Singapore, with more than 40,000 students from 100 countries enriching our vibrant and diverse campus community. We have also established our NUS Overseas Colleges programme in more than 15 cities around the world.`;
const sentence2 = `Our multidisciplinary and real-world approach to education, research and entrepreneurship enables us to work closely with industry, governments and academia to address crucial and complex issues relevant to Asia and the world. Researchers in our faculties, research centres of excellence, corporate labs and more than 30 university-level research institutes focus on themes that include energy; environmental and urban sustainability; treatment and prevention of diseases; active ageing; advanced materials; risk management and resilience of financial systems; Asian studies; and Smart Nation capabilities such as artificial intelligence, data science, operations research and cybersecurity.`;
const sentence3 = `For more information on NUS, please visit www.nus.edu.sg.`;

function NusParagraph(props) {
  return (
    <CommonParagraph
      sentence1={sentence1}
      sentence2={sentence2}
      sentence3={sentence3}
    />
  );
}

export default NusParagraph;
