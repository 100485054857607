import React, { useState } from "react";
import { Box } from "@mui/material";
import MentorCard from "../Components/MentorCard";
import Constants from "../utils/Constants.json";
import JamesProfile from "../Assets/JamesProfile.png";
import JiangchengProfile from "../Assets/JiangchengProfile.png";
import KevinProfile from "../Assets/KevinProfile.png";
import ReeteshProfile from "../Assets/reeteshProfile.png";
import SameerProfile from "../Assets/SameerProfile.png";
import WeihuiProfile from "../Assets/WeihuiProfile.png";
import WeijieProfile from "../Assets/weijieProfile.png";
import XiaotianProfile from "../Assets/XiaotianProfile.png";
import JundeProfile from "../Assets/jundeProfile.png";
import KorProfile from "../Assets/KorProfile.png";
import YucaoProfile from "../Assets/yucaoProfile.png";
import LeiXuProfile from "../Assets/leiXuProfile.png";
import YingProfile from "../Assets/YingProfile.png";
import wynProfile from "../Assets/wynProfile.png";
import tankokProfile from "../Assets/tankokProfile.png";
import sunilProfile from "../Assets/sunilProfile.png";
import yongxuProfile from "../Assets/yongxuProfile.png";
import quekProfile from "../Assets/quekProfile.png";
import roystonProfile from "../Assets/roystonProfile.png";
import shauProfile from "../Assets/shauProfile.png";
import AdrianEllison from "../Assets/AdrianEllisonProfile.png";
import peterQuekImg from "../Assets/PeterQUEK.jpg";

function FlexBox(props) {
  const { children } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: "30px",
        marginTop: "30px",
        fontSize: "40px",
        fontWeight: "bold",
      }}
    >
      {children}
    </Box>
  );
}

function CommunityPage() {
  const ying = {
    title: Constants.mentorYingTitle,
    jobTitle: Constants.mentorYingJobTitle,
    profileImg: YingProfile,
    role: "JUDGE",
    para1: Constants.yingPara1,
    para2: Constants.yingPara2,
    para3: Constants.yingPara3,
    para4: Constants.yingPara4,
    para5: Constants.yingPara5,
  };
  const wynthia = {
    title: Constants.WynTitle,
    jobTitle: Constants.WynJobTitle,
    profileImg: wynProfile,
    para1: Constants.WynPara1,
    para2: Constants.WynPara2,
    para3: Constants.WynPara3,
    para4: Constants.WynPara4,
  };
  const peterQuek = {
    title: Constants.PeterQuekTitle,
    jobTitle: Constants.PeterQuekJobTitle,
    para1: Constants.PeterQuekPara1,
    para2: Constants.PeterQuekPara2,
    para3: Constants.PeterQuekPara3,
    profileImg: peterQuekImg,
  };
  const jamesObj = {
    title: Constants.mentorJamesTitle,
    jobTitle: Constants.mentorJamesJobTitle,
    profileImg: JamesProfile,
    role: "JUDGE",
    para1: Constants.jamesPara1,
    para2: Constants.jamesPara2,
    para3: Constants.jamesPara3,
  };
  const tankok = {
    title: Constants.tankokTitle,
    jobTitle: Constants.tankokJobTitle,
    profileImg: tankokProfile,
    para1: Constants.tankokParagraph1,
    para2: Constants.tankokPara2,
  };
  const sunil = {
    profileImg: sunilProfile,
    title: Constants.sunilTitle,
    jobTitle: Constants.sunilJobTitle,
    para1: Constants.sunilPara1,
    para2: Constants.sunilPara2,
  };
  const yongxu = {
    title: Constants.yongxuTitle,
    jobTitle: Constants.yongxuJobTitle,
    para1: Constants.yongxuPara1,
    para2: Constants.yongxuPara2,
    profileImg: yongxuProfile,
  };

  const jiangChengObj = {
    title: Constants.mentorJiangChengTitle,
    jobTitle: Constants.mentorJiangChengJobTitle,
    profileImg: JiangchengProfile,
    role: "JUDGE",
    para1: Constants.mentorJiangChengParagraph1,
    para2: Constants.mentorJiangChengParagraph2,
  };
  const kevinObj = {
    title: Constants.mentorKevinChiewTitle,
    jobTitle: Constants.mentorKevinChiewJobTitle,
    profileImg: KevinProfile,
    role: "JUDGE",
    para1: Constants.kevinChiewParagraph1,
  };
  const reeteshObj = {
    title: Constants.reeteshTitle,
    jobTitle: Constants.reeteshJob,
    profileImg: ReeteshProfile,
    role: "JUDGE",
    para1: Constants.reeteshPara1,
    para2: Constants.reeteshPara2,
    para3: Constants.reeteshPara3,
    para4: Constants.reeteshPara4,
    scale: 0.4,
  };
  const sameerObj = {
    title: Constants.sameerTitle,
    jobTitle: Constants.sameerJob,
    profileImg: SameerProfile,
    role: "JUDGE",
    para1: Constants.sameerPara1,
    para2: Constants.sameerPara2,
    para3: Constants.sameerPara3,
    para4: Constants.sameerPara4,
  };
  const weihuiObj = {
    title: Constants.weihuiTitle,
    jobTitle: Constants.weihuiJob,
    profileImg: WeihuiProfile,
    role: "JUDGE",
    para1: Constants.weihuiPara1,
    scale: 0.4,
  };
  const xiaotianObj = {
    title: Constants.xiaoTianTitle,
    jobTitle: Constants.xiaoTianJob,
    profileImg: XiaotianProfile,
    role: "JUDGE",
    para1: Constants.xiaoTianPara1,
    scale: 0.4,
  };
  const jundeObj = {
    title: Constants.jundeTitle,
    jobTitle: Constants.jundeJob,
    profileImg: JundeProfile,
    role: "MENTOR",
    para1: Constants.jundePara1,
    scale: 0.4,
  };
  const korObj = {
    title: Constants.korPingTitle,
    jobTitle: Constants.korPingJob,
    profileImg: KorProfile,
    role: "MENTOR",
    para1: Constants.korPingPara1,
    para2: Constants.korPingPara2,
    para3: Constants.korPingPara3,
    para4: Constants.korPingPara4,
  };
  const shau = {
    title: Constants.shauryaTitle,
    jobTitle: Constants.shauryaJobTitle,
    para1: Constants.shauryaPara1,
    para2: Constants.shauryaPara2,
    profileImg: shauProfile,
  };
  const royston = {
    profileImg: roystonProfile,
    title: Constants.roystonTitle,
    jobTitle: Constants.roystonJobTitle,
    para1: Constants.roystonPara1,
    para2: Constants.roystonPara2,
  };
  const kor2024 = {
    title: Constants.korPingTitle,
    jobTitle: Constants.korPingJob,
    para1: Constants.quekPara1,
    para2: Constants.quekPara2,
    para3: Constants.quekPara3,
    profileImg: quekProfile,
  };
  const adrian = {
    title: Constants.adrainTitle,
    jobTitle: Constants.adrianJob,
    para1: Constants.adrainPara1,
    profileImg: AdrianEllison,
  };
  const weijieObj = {
    title: Constants.weiJieTitle,
    jobTitle: Constants.weiJieJob,
    profileImg: WeijieProfile,
    role: "MENTOR",
    para1: Constants.weiJiePara1,
  };
  const yucaoObj = {
    title: Constants.yuCaoTitle,
    jobTitle: Constants.yuCaoJob,
    profileImg: YucaoProfile,
    role: "MENTOR",
    para1: Constants.yuCaoPara1,
    para2: Constants.yuCaoPara2,
  };
  const leiXuObj = {
    title: Constants.leiXuTitle,
    jobTitle: Constants.leiXuJob,
    profileImg: LeiXuProfile,
    role: "MENTOR",
    para1: Constants.leiXuPara1,
    para2: Constants.leiXuPara2,
  };

  return (
    <Box sx={{}}>
      <FlexBox>Judges</FlexBox>
      <FlexBox>
        <MentorCard profileObj={ying} />
        <MentorCard profileObj={wynthia} />
        <MentorCard profileObj={peterQuek} />
      </FlexBox>
      <FlexBox>
        <MentorCard profileObj={jamesObj} />
        <MentorCard profileObj={tankok} />
        <MentorCard profileObj={kor2024} />
      </FlexBox>
      <FlexBox>Mentors</FlexBox>
      <FlexBox>
        <MentorCard profileObj={sunil} />
        <MentorCard profileObj={adrian} />
      </FlexBox>
      <FlexBox>
        <MentorCard profileObj={yongxu} />
        <MentorCard profileObj={royston} />
        <MentorCard profileObj={shau} />
      </FlexBox>
    </Box>
  );
}

export default CommunityPage;
