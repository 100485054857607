import React from "react";
import { Box } from "@mui/material";
import CompanyDescription from "./CompanyDescription";
import CompanyLogoBlock from "./CompanyLogoBlock";

function CompanyBlocks(props) {
  const { imageBlock, title, paragraph, link } = props;
  return (
    <Box sx={{ display: "flex", margin: "50px" }}>
      <CompanyLogoBlock logoBlock={imageBlock} />
      <CompanyDescription title={title} paragraph={paragraph} link={link} />
    </Box>
  );
}

export default CompanyBlocks;
