import React from "react";
import { Box } from "@mui/material";
import Paragraph from "../Components/Paragraph";

function TeamsPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 1000,
        margin: "auto auto 50px auto",
      }}
    >
      <Paragraph>
        Congratulations to the Top 8 Teams of the NUS-NCS Innovation Challenge!
        Your hard work and innovative solutions have earned you a spot in the
        finals, happening on 21 April 2024.
      </Paragraph>
      <Box sx={{ fontWeight: 1000, marginTop: "20px" }}>
        Top 8 Teams (in alphabetical order):
      </Box>
      <Paragraph>
        <ol>
          <li>BooleanPirates</li>
          <li>Bahrul Traffic System (BTS)</li>
          <li>CommutEvolve</li>
          <li>GenTPT</li>
          <li>GenTrafficGurus</li>
          <li>Green Path Traffic</li>
          <li>HowToTrainYourDragon</li>
          <li>North East</li>
        </ol>
      </Paragraph>
      <Paragraph>
        We would also like to extend a heartfelt thank you to all participating
        teams. Your courage to step out of your comfort zones to tackle this
        challenge has truly impressed us. Keep pushing boundaries and embracing
        innovation!
      </Paragraph>
    </Box>
  );
}

export default TeamsPage;
