import React from "react";
import CommonParagraph from "./CommonParagraph";
import ICTLogo from "../Assets/ICTLogo.png";
import { Box } from "@mui/material";
import CompanyBlocks from "./CompanyBlocks";

const sentence1 = `Huawei ICT Academy is a partnership between Huawei and Academies around the world. The academy’s aim is to educate, train and develop talents around the world, through this platform Huawei ICT Academy delivers Huawei ICT Technologies training, encouraging students to get Huawei certification and develop talents with the necessary practical skills for the ICT industry and community, bridging talent and industry demand.`;
function ICTParagraph(props) {
  return <CommonParagraph sentence1={sentence1} />;
}

function HuaweiIct(props) {
  const ictParagraph = <ICTParagraph />;
  const ictImage = (
    <Box
      component="img"
      alt="Huawei ICT Logo"
      src={ICTLogo}
      sx={{ maxWidth: "355px", maxHeight: "170px", margin: "100px" }}
    />
  );
  return (
    <CompanyBlocks
      imageBlock={ictImage}
      title="HUAWEI ICT ACADEMY"
      paragraph={ictParagraph}
      link="https://e.huawei.com/en/talent/ict-academy/#/home"
    />
  );
}

export default HuaweiIct;
