import React from "react";
import { Box } from "@mui/material";
import OrgButton from "./OrgButton";
import Paragraph from "./Paragraph";

function CompanyDescription(props) {
  const { title, paragraph, link } = props;
  return (
    <Box>
      <Box sx={{ fontWeight: 1000, fontSize: "40px" }}>{title}</Box>
      <Paragraph>{paragraph}</Paragraph>
      <OrgButton label="CLICK HERE FOR MORE INFORMATION" link={link} />
    </Box>
  );
}

export default CompanyDescription;
