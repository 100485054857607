import React from "react";
import { Box } from "@mui/material";
import HuaweiLogo from "../Assets/HuaweiLogo.png";
import HuaweiParagraph from "./HuaweiParagraph";
import CompanyBlocks from "./CompanyBlocks";

function HuaweiInfo(props) {
  const huaweiParagraph = <HuaweiParagraph />;
  const huaweiImage = (
    <Box
      component="img"
      alt="Huawei Logo"
      src={HuaweiLogo}
      sx={{ maxWidth: "355px", maxHeight: "170px", margin: "100px" }}
    />
  );
  return (
    <CompanyBlocks
      imageBlock={huaweiImage}
      title="Huawei"
      paragraph={huaweiParagraph}
      link="https://www.huaweicloud.com/intl/en-us/"
    />
  );
}

export default HuaweiInfo;
