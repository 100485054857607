import React from "react";
import { Box } from "@mui/material";
import TimelineContent from "../Assets/TimelineContent.jpg";

function TimelinePage() {
  return (
    <Box
      sx={{
        maxWidth: "1000px",
        margin: "50px auto",
        display: "flex",
        flexDirection: "column",
        gap: "70px",
      }}
    >
      <Box
        component="img"
        alt="Final Event"
        src={TimelineContent}
        sx={{ width: "100%" }}
      />
    </Box>
  );
}

export default TimelinePage;
