import React from "react";
import CommonParagraph from "./CommonParagraph";

const sentence1 = `Focused on digital government, economy and society sectors, Audaque has developed intelligent data products which cover whole data lifecycle and business solutions, helping the intelligent upgrading of 26 provinces, 58 cities and 45 local government and industrial clients of government, transportation, energy, finance, etc.`;

function AudaqueParagraph(props) {
  return <CommonParagraph sentence1={sentence1} />;
}

export default AudaqueParagraph;
