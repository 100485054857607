import React from "react";
import { Box } from "@mui/material";
import InfoRow from "./InfoRow";

function CommonParagraph(props) {
  return (
    <Box>
      {props.sentence1 && <InfoRow label={props.sentence1} />}
      {props.sentence2 && <InfoRow label={props.sentence2} />}
      {props.sentence3 && <InfoRow label={props.sentence3} />}
      {props.sentence4 && <InfoRow label={props.sentence4} />}
    </Box>
  );
}

export default CommonParagraph;
