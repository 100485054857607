import React from "react";
import { Box } from "@mui/material";

function FaQsPage(props) {
  let FAQ_Link =
    "https://github.com/cryptodogofmax/SG-AI-Competition/blob/ad0006cd7aefe27e614d2d7663da838c867aa1a8/FAQ-NUS_Huawei-240223.pdf";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "50px auto 50px auto",
      }}
    >
      The detailed FAQs can be downloaded through this&nbsp;{" "}
      <a href={FAQ_Link} target="_blank" rel="noopener noreferrer">
        link
      </a>
    </Box>
  );
}

export default FaQsPage;
