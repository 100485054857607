import React from "react";
import { Button } from "@mui/material";

function OrgButton(props) {
  const { label, link, handleClick } = props;
  return (
    <Button
      variant="contained"
      size="large"
      style={{ borderRadius: 50 }}
      href={link ? link : ""}
      onClick={handleClick ? handleClick : null}
    >
      {label}
    </Button>
  );
}

export default OrgButton;
