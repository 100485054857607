import React from "react";
import TimelineImage from "../Assets/TimelineImage.jpg";
import { Box } from "@mui/material";
import { spinalCase, timeline } from "../utils/utils";
import TimelineTitle from "../Assets/TimelineTitle.png";

function Timeline() {
  return (
    <Box>
      <Box
        component="img"
        alt="Timeline"
        src={TimelineTitle}
        sx={{ width: "200px" }}
      />
      <Box
        component="img"
        alt="Timeline"
        src={TimelineImage}
        sx={{ width: "100%" }}
      />
      <Box>
        For the full timeline and details, please visit the{" "}
        <a href={spinalCase(timeline)}>Timeline</a> tab.
      </Box>
    </Box>
  );
}

export default Timeline;
