import { Box } from "@mui/material";
import { SmallDarkBlueButton } from "./SmallDarkBlueButton";
import { pagesLinkObj } from "../utils/utils";

export function ButtonNavigation() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        maxWidth: "1300px",
        margin: "70px auto 50px auto",
      }}
    >
      <SmallDarkBlueButton label="Brief" link={pagesLinkObj["Brief"]} />
      <SmallDarkBlueButton label="Timeline" link={pagesLinkObj["Timeline"]} />
      <SmallDarkBlueButton label="Challenge" link={pagesLinkObj["Challenge"]} />
      <SmallDarkBlueButton label="Event" link={pagesLinkObj["Event"]} />
      <SmallDarkBlueButton label="Community" link={pagesLinkObj["Community"]} />
      <SmallDarkBlueButton label="Teams" link={pagesLinkObj["Teams"]} />
      {/*<SmallDarkBlueButton label="Rules" link={pagesLinkObj["Rules"]} />*/}
    </Box>
  );
}
