import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Paragraph from "./Paragraph";

function MentorCard(props) {
  const [open, setOpen] = React.useState(false);
  const { profileObj } = props;
  const { profileImg, title, jobTitle, para1, para2, para3, para4, para5 } =
    profileObj;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          borderRadius: "50px",
          backgroundColor: "#D4F8D7",
          display: "flex",
          maxWidth: "350px",
          width: "100%",
          height: "780px",
          flexDirection: "column",
          margin: "0 auto",
          padding: "50px 42px",
        }}
      >
        <Box
          component="img"
          alt={title}
          src={profileImg}
          sx={{
            aspectRatio: "1.05",
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            overflow: "hidden",
            borderRadius: "50%",
          }}
        />
        <Box
          sx={{
            color: "#01225E",
            textAlign: "center",
            margin: "82px -10px auto -10px",
            font: "700 30px Inter, sans-serif ",
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            color: "#01225E",
            textAlign: "center",
            alignSelf: "center",
            margin: "30px -10px auto -10px",
            maxWidth: "341px",
            font: "400 20px Inter, sans-serif ",
            height: "120px",
          }}
        >
          {jobTitle}
        </Box>
        <Button
          style={{
            borderRadius: "50px",
            backgroundColor: "#FFF",
            alignSelf: "center",
            display: "flex",
            marginTop: "70px",
            width: "337px",
            maxWidth: "100%",
            height: "60px",
            flexDirection: "column",
            color: "#01225E",
            fontSize: "25px",
            fontWeight: 700,
          }}
          onClick={handleClickOpen}
        >
          Read More
        </Button>
      </Box>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Paragraph>{title}</Paragraph>
          <Paragraph>{jobTitle}</Paragraph>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
        >
          <Paragraph>{para1}</Paragraph>
          <Paragraph>{para2}</Paragraph>
          <Paragraph>{para3}</Paragraph>
          <Paragraph>{para4}</Paragraph>
          <Paragraph>{para5}</Paragraph>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default MentorCard;
