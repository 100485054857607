import React from "react";
import { Box } from "@mui/material";

function LogoBlock(props) {
  return (
    <Box
      sx={{
        minWidth: "40%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.children}
    </Box>
  );
}

export default LogoBlock;
