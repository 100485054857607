import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6DD696",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, date, title, venue, time) {
  return { name, date, title, venue, time };
}

const rows = [
  createData(
    "1st event",
    "8 Feb 2024",
    "NUS – NCS Innovation Challenge Kick Off",
    "Lecture Theatre 15 (LT15) National University of Singapore, 11 Computing Drive, Singapore 117416",
    "6:30pm - 9.30pm"
  ),
  createData(
    "2nd event",
    "22 Feb 2024",
    "NUS – NCS Innovation Challenge Enablement Session",
    "Lecture Theatre 15 (LT15) National University of Singapore, 11 Computing Drive, Singapore 117416",
    "6:30pm - 9.30pm"
  ),
  createData(
    "3rd event",
    "21 Apr 2024",
    "NUS – NCS Innovation Challenge Final Presentation",
    "NCS Hub",
    "10:00am - 2.00pm"
  ),
];

export default function EventPage() {
  return (
    <TableContainer
      component={Paper}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Table
        sx={{ minWidth: 300, maxWidth: 800, marginBottom: "50px" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Event</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell sx={{ minWidth: 150 }}>8 Feb 2024</StyledTableCell>
            <StyledTableCell sx={{ maxWidth: 600 }}>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>
                  NUS – NCS Innovation Challenge Kick Off
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>Venue:</span> Lecture
                  Theatre 15 (LT15) National University of Singapore, 11
                  Computing Drive, Singapore 117416
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>Time:</span> 6:30pm -
                  9.30pm
                </Box>
                <Box sx={{ wordWrap: "break-word" }}>
                  <span style={{ fontWeight: "bold" }}>Zoom Recording: </span>
                  <a href="https://nus-sg.zoom.us/rec/share/Uu0UNabqc_VEZ3LBSkej4qhkyZVGSNkNaJqPNQ2lEhr4zTr4aq3tUqaISN7fKAEd.8P_y8wzEf3u9tRR0">
                    https://nus-sg.zoom.us/rec/share/Uu0UNabqc_VEZ3LBSkej4qhkyZVGSNkNaJqPNQ2lEhr4zTr4aq3tUqaISN7fKAEd.8P_y8wzEf3u9tRR0
                  </a>
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>
                    Zoom Recording Passcode:{" "}
                  </span>
                  !6?S6l4j
                </Box>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>22 Feb 2024</StyledTableCell>
            <StyledTableCell>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>
                  NUS – NCS Innovation Challenge Enablement Session
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>Venue:</span> Lecture
                  Theatre 15 (LT15) National University of Singapore, 11
                  Computing Drive, Singapore 117416
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>Time:</span> 6:30pm -
                  9.30pm
                </Box>
                <Box sx={{ wordWrap: "break-word" }}>
                  <span style={{ fontWeight: "bold" }}>Zoom Recording: </span>
                  <a href="https://nus-sg.zoom.us/rec/share/WSxm46B6mhw_xrkEaRktMxZ61vr_SXUAMUXdtXjehze8Vy8ngtSjx2pyCvUGAvH8.H4fMyBZgOKGeRaV1?startTime=1708597731000">
                    https://nus-sg.zoom.us/rec/share/WSxm46B6mhw_xrkEaRktMxZ61vr_SXUAMUXdtXjehze8Vy8ngtSjx2pyCvUGAvH8.H4fMyBZgOKGeRaV1?startTime=1708597731000
                  </a>
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>
                    Zoom Recording Passcode:{" "}
                  </span>
                  m7@B^WEm
                </Box>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>21 Apr 2024</StyledTableCell>
            <StyledTableCell>
              <Box>
                <Box sx={{ fontWeight: "bold" }}>
                  NUS – NCS Innovation Challenge Final Presentation
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>Venue:</span> NCS Hub
                </Box>
                <Box>
                  <span style={{ fontWeight: "bold" }}>Time:</span> 10:00am -
                  2.00pm
                </Box>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
