import { Button } from "@mui/material";
import React from "react";

export function SmallDarkBlueButton(props) {
  const { label, link, handleClick } = props;
  return (
    <Button
      variant="contained"
      size="small"
      sx={{
        borderRadius: "50px",
        background: "#01225E",
        fontSize: "20px",
        fontWeight: 400,
        letterSpacing: "2.8px",
        height: "40px",
        padding: "4px 35px",
      }}
      href={link ? link : ""}
      onClick={handleClick ? handleClick : null}
    >
      {label}
    </Button>
  );
}
