import React from "react";
import { Box } from "@mui/material";
import PageTitle from "../Assets/PageTitle.png";

function PageHeader() {
  return (
    <Box
      component="img"
      alt="Page Title"
      src={PageTitle}
      sx={{ width: "100%" }}
    />
  );
}

export default PageHeader;
