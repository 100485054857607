import { Button } from "@mui/material";
import React from "react";

export function DarkBlueButton(props) {
  const { label, link, handleClick } = props;
  return (
    <Button
      variant="contained"
      size="large"
      sx={{
        borderRadius: "54px",
        background: "#01225E",
        fontSize: "25px",
        fontWeight: 700,
        letterSpacing: "2.8px",
        height: "65px",
        width: "380px",
      }}
      href={link ? link : ""}
      onClick={handleClick ? handleClick : null}
    >
      {label}
    </Button>
  );
}
