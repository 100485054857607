import React from "react";
import CommonParagraph from "./CommonParagraph";

const sentence1 = `Founded in 1987, Huawei is a leading global provider of information and communications technology (ICT) infrastructure and smart devices. We have approximately 195,000 employees and we operate in over 170 countries and regions, serving more than three billion people around the world.`;
const sentence2 = `Huawei's mission is to bring digital to every person, home and organisation for a fully connected, intelligent world. To this end, we will drive ubiquitous connectivity and promote equal access to networks to lay the foundation for the intelligent world; provide diversified computing power to deliver ubiquitous cloud and intelligence; build powerful digital platforms to help all industries and organisations become more agile, efficient, and dynamic; redefine user experience with AI, offering consumers a more personalised and intelligent experience across all scenarios, including home, travel, office, entertainment, and fitness & health.`;

function HuaweiParagraph(props) {
  return <CommonParagraph sentence1={sentence1} sentence2={sentence2} />;
}

export default HuaweiParagraph;
