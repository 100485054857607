import React from "react";
import { Box } from "@mui/material";

function RulesPage(props) {
  let rulesLink =
    "https://github.com/cryptodogofmax/Innovation-Challenge-2024/blob/b381cda5fb19699994392059c16a2f2fbefca874/rules2024.pdf";
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "50px auto 50px auto",
      }}
    >
      The detailed rules can be downloaded through this&nbsp;{" "}
      <a href={rulesLink} target="_blank" rel="noopener noreferrer">
        link
      </a>
    </Box>
  );
}

export default RulesPage;
