import React from "react";
import { Box } from "@mui/material";
import ProblemStatementContent from "../Assets/ProblemStatementContent.png";

function ProblemStatement() {
  return (
    <Box>
      <Box
        component="img"
        alt="Final Event"
        src={ProblemStatementContent}
        sx={{ width: "100%" }}
      />
    </Box>
  );
}

export default ProblemStatement;
