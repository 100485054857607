import React from "react";
import { Box } from "@mui/material";
import ProblemStatement from "../Components/ProblemStatement";
import Timeline from "../Components/Timeline";
import PrizeInfo from "../Components/PrizeInfo";
import FinalEventDate from "../Components/FinalEventDate";

function BriefPage() {
  return (
    <Box
      sx={{
        maxWidth: 1000,
        margin: "50px auto",
        display: "flex",
        flexDirection: "column",
        gap: "70px",
      }}
    >
      <ProblemStatement />
      <Timeline />
      <FinalEventDate />
      <PrizeInfo />
    </Box>
  );
}

export default BriefPage;
