import React from "react";
import { Box } from "@mui/material";
import NUSLogo from "../Assets/NUSLogo.png";
import NusParagraph from "./NusParagraph";
import CompanyBlocks from "./CompanyBlocks";

function NusInfo(props) {
  const nusParagraph = <NusParagraph />;
  const nusImage = (
    <Box
      component="img"
      alt="NUS Logo"
      src={NUSLogo}
      sx={{ maxWidth: "355px", maxHeight: "170px", margin: "100px" }}
    />
  );
  return (
    <CompanyBlocks
      imageBlock={nusImage}
      title="NATIONAL UNIVERSITY OF SINGAPORE (NUS)"
      paragraph={nusParagraph}
      link="https://www.nus.edu.sg"
    />
  );
}

export default NusInfo;
