import React from "react";
import { Box, Grid, useMediaQuery } from "@mui/material";
import RegistrationButton from "./RegistrationButton";

function RegisterBanner() {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        maxWidth: "1000px",
        textAlign: "center",
        margin: largeScreen ? "-100px auto 15px auto" : "auto auto 15px auto",
      }}
    >
      {" "}
      {largeScreen ? (
        <Grid
          container
          direction={largeScreen ? "row" : "column"}
          rowSpacing={1}
          columnSpacing={{ xs: 6, sm: 3, md: 3 }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xl={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              width: largeScreen ? "400px" : "80%",
              textAlign: "center",
              maxWidth: "400px",
            }}
          ></Grid>
          <Grid item>
            <RegistrationButton />
          </Grid>
        </Grid>
      ) : (
        <RegistrationButton />
      )}
    </Box>
  );
}

export default RegisterBanner;
