import React from "react";
import { Box } from "@mui/material";
import MakingTravel from "../Assets/MakingTravel.png";
import ParagraphPD2023 from "../Components/ParagraphPD2023";
import ParagraphPd2023 from "../Components/ParagraphPD2023";

function Paragraph(props) {
  const { children } = props;
  return <Box sx={{ marginTop: "5px" }}>{children}</Box>;
}

function backgroundContent() {
  return (
    <Box>
      <Paragraph>
        Generative AI has the potential to transform the transport sector,
        redefining how cities manage traffic and public transport systems. The
        application of Generative AI in traffic management involves leveraging
        real-time data from various sources such as IoT devices, traffic
        cameras, and in-vehicle GPS systems to perform dynamic traffic pattern
        analysis and redistribution across the transport network. This helps
        transport agencies optimise the use of scarce network capacity to reduce
        traffic congestion and improve the commuting experience.
      </Paragraph>
      <Paragraph>
        Managing traffic flows and reducing idle times, contributes to lower
        carbon emissions and energy consumption. This aligns with global efforts
        to create environmentally friendly and sustainable cities.
      </Paragraph>
      <Paragraph>
        Additionally, Generative AI can be used to provide route planning that
        takes into account individual context and events across the broader
        transport network, ensuring trips are safe and convenient.
      </Paragraph>
      <Paragraph>
        Our innovation challenge, "Making Travel a Breeze with TransportGPT,"
        seeks to explore these innovative applications of Generative AI.
        Participants will have the opportunity to develop solutions that not
        only address current traffic and transport inefficiencies but also
        contribute to the future of eco-friendly and intelligent urban mobility.
        This event is a call to action for creative minds to shape the future of
        transport, making it safer, more efficient, and sustainable for
        everyone.
      </Paragraph>
      <Paragraph sx={{ textDecoration: "underline", fontWeight: "bold" }}>
        Analytical Approach could include the following:
      </Paragraph>
      <ul>
        <li>
          Develop approach to retrieve relevant chunks of information from the
          underlying data sources for the LLM
        </li>
        <li>
          Train LLMs to understand traffic patterns using diverse
          traffic-related datasets, and to produce quality responses/completions
          for network and route planning
        </li>
        <li>Evaluate the relevance of the LLM responses or completions</li>
        <li>
          Develop the framework to trade-off performance and accuracy of the LLM
        </li>
        <li>
          Implement Natural Language Processing (NLP) for interpreting
          unstructured data from social media and other textual sources.
        </li>
        <li>
          Develop algorithms for real-time data processing and decision-making
          based on LLM outputs.
        </li>
      </ul>
    </Box>
  );
}

function objectivesContent() {
  return (
    <Box>
      <Paragraph>
        Develop an innovative road traffic management prototype system that
        harnesses Generative AI and in particular Large Language Models (LLMs)
        to interpret, predict, and respond to dynamic road traffic conditions.
        The challenge involves developing and training LLMs with existing
        traffic data sources and communication systems to enhance traffic flow,
        reduce congestion, promote greener mobility options and improve overall
        road safety in urban environments.
      </Paragraph>
      <Paragraph>
        Please note that the challenge is intentionally designed to be open to
        foster innovation and creativity. This deliberate openness grants
        participants the flexibility to define their use case scenarios as they
        see fit, aligning with the innovative nature of this challenge.
      </Paragraph>
      <Paragraph>
        Despite the expected breadth of proposals, rest assured that the
        evaluation process will be conducted objectively. Key assessment
        criteria include clarity in defining the problem statement, focusing on
        the identification of current pain points and their significance.
        Additionally, we will evaluate participants’ perspectives on why
        Generative AI is crucial in addressing the identified problem. The
        execution of the Generative AI solution will also play a significant
        role in the evaluation process.
      </Paragraph>
    </Box>
  );
}

function dataSource() {
  return (
    <Box>
      <Paragraph sx={{ textDecoration: "underline", fontWeight: "bold" }}>
        Data Source Potential Datasets:
      </Paragraph>
      <ul>
        <li>Real-time and historical traffic flow data</li>
        <li>Public transport patronage</li>
        <li>GPS tracking data from public and private vehicles</li>
        <li>Traffic camera feeds and sensor data</li>
        <li>Social media feeds for user-reported traffic conditions.</li>
        <li>Emergency response and accident reports</li>
      </ul>
      <Paragraph>
        Some of the above datasets are available from the public LTA DataMall
        (https://datamall.lta.gov.sg/content/datamall/en.html). You are allowed
        to use any independently verified public data source.
      </Paragraph>
    </Box>
  );
}

function taskDetail() {
  return (
    <Box>
      <Paragraph>
        Specifically, design a concept and accompanying application prototype
        that demonstrates how Generative AI could be used to:
      </Paragraph>
      <ol>
        <li>
          Interpret traffic data and manage traffic conditions in a road
          transport network. Analyse and interpret vast amounts of traffic data
          of different quality from various sources (e.g., traffic cameras,
          sensors, GPS data from vehicles, social media updates) to gain
          insights into current traffic conditions, so as to:
        </li>
        <ul>
          <li>
            Identify traffic and movement patterns that are associated with
            problems in the near (30 minutes, one hour, three hours, 12 hours)
            or longer term future (months or years), and
          </li>
          <li>
            Provide options for managing current traffic conditions – such as
            police deployment, additional bus services, variable message sign
            communications, etc.)
          </li>
        </ul>
        <li>
          Enhance communication with motorists. Provide real-time, context-aware
          traffic updates and navigation advisories to drivers through existing
          in-car systems or smartphone-based applications.
        </li>
      </ol>
    </Box>
  );
}

function completeWork() {
  return (
    <Box>
      <Paragraph>
        The following complete work needs to be submitted for both the Top 8 and
        Final Round phases:
      </Paragraph>
      <ol>
        <li>
          Executive Summary (1page): Summarise the key points of your solution.
          It should restate the purpose of the study and highlight the major
          points of the solution and the dataset you use. Include the main
          findings and any recommendations.
        </li>
        <li>
          Solution source code: Submit through the official website of the
          competition, bundling the source code of any prototypes developed with
          the corresponding datasets (with the appropriate annotations).
        </li>
        <li>
          Presentation slides: Submit through the official website of the
          competition.
        </li>
      </ol>
      <Paragraph>
        Note: Please package the transaction program source code and
        presentation slides into a .zip file during the submission.
      </Paragraph>
    </Box>
  );
}

function qualif() {
  return (
    <Box>
      <Paragraph>Effectivity of Solution (80%)</Paragraph>
      <Paragraph>Presentation Quality of the Solution (20%)</Paragraph>
    </Box>
  );
}

function finalCriteria() {
  return (
    <Box>
      <Paragraph sx={{ fontWeight: "bold" }}>
        The evaluation will be a 1-to-5 point scale in the areas of:
      </Paragraph>
      <ul>
        <li>Innovative Approach and Feasibility of Model</li>
        <li>Model Quality & Reliability</li>
        <li>Creative sourcing and usage of the data</li>
        <li>Value Creation</li>
        <li>Storytelling and presentation of the solution</li>
      </ul>
    </Box>
  );
}

function guideline() {
  return (
    <Box>
      <Paragraph>
        You will need to present a working model of your solution. It should
        provide the data, codes and presentation slides. This presentation
        serves as a guide for your presentation submission. Please remember that
        you will also be judged based on the quality of the PowerPoint
        presentation.
      </Paragraph>
      <Paragraph>Here are a few guidelines for you to follow:</Paragraph>
      <ul>
        <li>Your presentation should be at most 10 mins.</li>
        <li>Explain the challenges that your solution will be addressing.</li>
        <li>Explanation of how you derive your solution.</li>
        <li>
          Demonstrate the software tools or services you use in this competition
          and their function.
        </li>
        <li>Include only essential points.</li>
        <li>Use graphics effectively.</li>
        <li>
          Make sure all the information is viewable during presentation mode.
        </li>
        <li>
          Give credit where credit is due. References and links should also be
          placed on the last slide.
        </li>
      </ul>
    </Box>
  );
}

function rules() {
  let rulesLink =
    "https://github.com/cryptodogofmax/Innovation-Challenge-2024/blob/94f2d0fde59ccc26c5fe5d3b52db27770ea65da7/Rules-20240403.pdf";
  return (
    <Box>
      The detailed rules can be downloaded through this&nbsp;{" "}
      <a href={rulesLink} target="_blank" rel="noopener noreferrer">
        link
      </a>
    </Box>
  );
}

function ProblemDescriptionPage() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 1000,
        margin: "50px auto",
        gap: "40px",
      }}
    >
      <Box
        component="img"
        alt="Making travel a breeze"
        src={MakingTravel}
        sx={{ width: "100%" }}
      />
      <ParagraphPD2023 title="Background" content={backgroundContent()} />
      <ParagraphPD2023
        title="Task Objectives"
        content={objectivesContent()}
      ></ParagraphPD2023>
      <ParagraphPD2023
        title="Data Source"
        content={dataSource()}
      ></ParagraphPD2023>
      <ParagraphPd2023
        title="Task Detail Description"
        content={taskDetail()}
      ></ParagraphPd2023>
      <ParagraphPD2023
        title="Complete Work Submission"
        content={completeWork()}
      ></ParagraphPD2023>
      {/*<ParagraphPD2023*/}
      {/*  title="Qualification to become Top 8 Evaluation Criteria "*/}
      {/*  content={qualif()}*/}
      {/*></ParagraphPD2023>*/}
      <ParagraphPD2023
        title="Final Evaluation Criteria"
        content={finalCriteria()}
      ></ParagraphPD2023>
      <ParagraphPD2023
        title="Presentation Guidelines"
        content={guideline()}
      ></ParagraphPD2023>
      <ParagraphPD2023 title="Rules" content={rules()} />
    </Box>
  );
}

export default ProblemDescriptionPage;
