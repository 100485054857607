import React from "react";
import CommonParagraph from "./CommonParagraph";

const sentence1 = `In 2013, NUS Business Analytics Centre (BAC) was established in collaboration with IBM to develop a talent pool of business analytics professionals to meet the needs of companies looking to improve their businesses through the use of analytics. In a 5-year collaboration that ensued, NUS offered academic expertise to design, craft and conduct the Master of Science in Business Analytics (MSBA) programme while IBM contributed industrial knowledge.`;
const sentence2 = `Global interest in the field of business data analytics has since grown tremendously, with companies across various industries actively seeking for talents who excel in the analytics field.`;
const sentence3 = `To date, near to 350 industrial analytics projects were completed by NUS MSBA students, with the school forming valuable partnerships with more than 100 organisations.`;
const sentence4 = `For more information on NUS Master of Science in Business Analytics, please visit https://msba.nus.edu.sg/`;

function BacParagraph(props) {
  return (
    <CommonParagraph
      sentence1={sentence1}
      sentence2={sentence2}
      sentence3={sentence3}
      sentence4={sentence4}
    />
  );
}

export default BacParagraph;
