import React from "react";
import BacParagraph from "./BacParagraph";
import { Box } from "@mui/material";
import BacLogo from "../Assets/BacLogo.png";
import CompanyBlocks from "./CompanyBlocks";

function BacInfo(props) {
  const bacParagraph = <BacParagraph />;
  const bacImage = (
    <Box
      component="img"
      alt="BAC Logo"
      src={BacLogo}
      sx={{ maxWidth: "355px", maxHeight: "170px", margin: "100px" }}
    />
  );

  return (
    <CompanyBlocks
      imageBlock={bacImage}
      title="NUS BUSINESS ANALYTICS CENTRE (BAC)"
      paragraph={bacParagraph}
      link="https://msba.nus.edu.sg/"
    />
  );
}

export default BacInfo;
