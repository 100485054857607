import React from "react";
import { Box } from "@mui/material";
import HuaweiInfo from "../Components/HuaweiInfo";
import NUSInfo from "../Components/NUSInfo";
import AudaqueInfo from "../Components/AudaqueInfo";
import BacInfo from "../Components/BacInfo";
import HuaweiICT from "../Components/HuaweiICT";
function DarkBox(props) {
  const { children } = props;
  return (
    <Box
      sx={{
        background: "#F3F4F7",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
    >
      {children}
    </Box>
  );
}

function AboutUsPage(props) {
  return (
    <Box>
      <HuaweiInfo />
      <DarkBox>
        <NUSInfo />
      </DarkBox>
      <AudaqueInfo />
      <DarkBox>
        <HuaweiICT />
      </DarkBox>
      <BacInfo />
    </Box>
  );
}

export default AboutUsPage;
