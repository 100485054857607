import React from "react";
import { DarkBlueButton } from "./DarkBlueButton";

function RegistrationButton() {
  let registerLink =
    "https://ddec1-0-en-ctp.trendmicro.com:443/wis/clicktime/v1/query?url=https%3a%2f%2fbit.ly%2fNUS%2dNCS&umid=b285dc40-2641-4477-8861-a325194f8a98&auth=8d3ccd473d52f326e51c0f75cb32c9541898e5d5-46cb36f1df0049e49cd7dbf86dc842314f7b2a5a";
  let submitLink = "https://bit.ly/NUS-NCS-submit";
  return <DarkBlueButton label="Submit Now" link={submitLink}></DarkBlueButton>;
}

export default RegistrationButton;
