import "./App.css";
import AppRouter from "./routes/route";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

const CustomTheme = createTheme({
  palette: {
    mode: "light",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={CustomTheme}>
      <CssBaseline />
      <main>
        <AppRouter />
      </main>
    </ThemeProvider>
  );
}

export default App;
