const brief = "Brief";
const timeline = "Timeline";
const problemDescription = "Challenge";
const eventTabName = "Event";
const community = "Community";
const faqsTabName = "FAQs";
const aboutUs = "About Us";
const teams = "Teams";
const rules = "Rules";

const pagesValueObj = {
  1: brief,
  2: timeline,
  3: problemDescription,
  4: eventTabName,
  5: community,
  6: faqsTabName,
  7: aboutUs,
  8: teams,
  9: rules,
};

function spinalCase(str) {
  return (
    "/" +
    str
      .split(" ") //splits the string into pieces at spaces
      .map((c) => c.toLowerCase()) //makes each piece lowercase
      .join("-")
  ); //combines each piece with a "-"
}

const pagesLinkObj = {
  [brief]: spinalCase(brief),
  [timeline]: spinalCase(timeline),
  [problemDescription]: spinalCase(problemDescription),
  [eventTabName]: spinalCase(eventTabName),
  [community]: spinalCase(community),
  [faqsTabName]: spinalCase(faqsTabName),
  [aboutUs]: spinalCase(aboutUs),
  [teams]: spinalCase(teams),
  [rules]: spinalCase(rules),
};

export {
  brief,
  timeline,
  problemDescription,
  eventTabName,
  community,
  faqsTabName,
  aboutUs,
  teams,
  rules,
  pagesValueObj,
  pagesLinkObj,
  spinalCase,
};
