import React from "react";
import PageHeader from "./PageHeader";
import { Outlet } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/material";
import RegisterBanner from "./RegisterBanner";
import { ButtonNavigation } from "./ButtonNavigation";

function Layout() {
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: largeScreen ? "hidden" : "auto",
        width: largeScreen ? "90vw" : "100%",
        margin: "auto",
      }}
    >
      <Box>
        <PageHeader />
        <RegisterBanner />
        <ButtonNavigation />
        <Outlet />
      </Box>
    </Box>
  );
}

export default Layout;
