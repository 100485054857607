import React from 'react';
import Layout from "../Components/Layout";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import BriefPage from "../Pages/BriefPage";
import {
    aboutUs,
    brief,
    community,
    eventTabName,
    faqsTabName,
    problemDescription,
    rules,
    spinalCase,
    teams,
    timeline
} from "../utils/utils";
import ProblemDescriptionPage from "../Pages/ProblemDescriptionPage";
import CommunityPage from "../Pages/CommunityPage";
import FAQsPage from "../Pages/FAQsPage";
import AboutUsPage from "../Pages/AboutUsPage";
import TeamsPage from "../Pages/TeamsPage";
import RulesPage from "../Pages/RulesPage";
import TimelinePage from "../Pages/TimelinePage";
import EventPage from "../Pages/EventPage";

const AppRouter = props => (
    <Router>
        <Routes>
            <Route element={<Layout/>}>
                <Route path='/' element={<BriefPage/>}/>
                <Route path={spinalCase(brief)} element={<BriefPage/>}/>
                <Route path={spinalCase(timeline)} element={<TimelinePage/>}/>
                <Route path={spinalCase(problemDescription)} element={<ProblemDescriptionPage/>}/>
                <Route path={spinalCase(eventTabName)} element={<EventPage/>}/>
                <Route path={spinalCase(community)} element={<CommunityPage/>}/>
                <Route path={spinalCase(faqsTabName)} element={<FAQsPage/>}/>
                <Route path={spinalCase(aboutUs)} element={<AboutUsPage/>}/>
                <Route path={spinalCase(teams)} element={<TeamsPage/>}/>
                <Route path={spinalCase(rules)} element={<RulesPage/>}/>
            </Route>
        </Routes>
    </Router>
);

export default AppRouter;