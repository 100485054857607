import React from "react";
import { Box } from "@mui/material";

function ParagraphPd2023(props) {
  const { title, content } = props;
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Box
        sx={{
          fontWeight: "bold",
          fontSize: 20,
          marginTop: "10px",
          marginBottom: "10px",
          textDecoration: "underline",
          textDecorationColor: "#00A7E1",
          textDecorationThickness: "15px",
        }}
      >
        {title}
      </Box>
      <br />
      <Box>{content}</Box>
    </Box>
  );
}

export default ParagraphPd2023;
