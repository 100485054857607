import React from "react";
import { Box } from "@mui/material";
import CompanyBlocks from "./CompanyBlocks";
import AudaqueLogo from "../Assets/AudaqueLogo.png";
import AudaqueParagraph from "./AudaqueParagraph";

function AudaqueInfo(props) {
  const audaqueParagraph = <AudaqueParagraph />;
  const audaqueImage = (
    <Box
      component="img"
      alt="Audaque Logo"
      src={AudaqueLogo}
      sx={{ maxWidth: "355px", maxHeight: "170px", margin: "100px" }}
    />
  );
  return (
    <CompanyBlocks
      imageBlock={audaqueImage}
      title="SHENZHEN AUDAQUE TECHNOLOGY"
      paragraph={audaqueParagraph}
      link="http://www.audaque.com/#1"
    />
  );
}

export default AudaqueInfo;
